

































































import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from "@vue/composition-api";
import { i18n } from "@/locales/i18n";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import StickerCard from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayBasket/components/StickerCard/StickerCard.vue";
import {
  CartElement,
  CartHighwayStickerQueryResponse,
} from "@/core/dataTypes/types";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export default defineComponent({
  name: "ValidStickersPage",
  setup() {
    const page = ref(1);
    const pageCount = ref(0);
    const totalVisible = ref(7);
    const hdCustomerVehicleList = ref([]);
    const stickerCardItems = ref<CartElement[] | unknown>();
    const hdCustomerVehicleListLoading = ref(true);
    const postCartHistoryQueryLoading = computed(
      stickerFacade.postCartHistoryQueryLoading
    );

    const onChangePagination = () => {
      getHighwayStickerQuery(page.value);
    };

    const onNavigationDashboardPage = () => {
      router.push(getLanguageStaticRoute(ERoutes.DASHBOARD));
    };

    const getHighwayStickerQuery = (page: number) => {
      return stickerFacade
        .postHighwayStickerQuery({
          queryParams: {
            onlyValidStickers: true,
          },
          paginationParams: {
            rows: 8,
            page: page,
          },
        })
        .then((res: CartHighwayStickerQueryResponse) => {
          stickerCardItems.value = res.rowList?.highwayStickerQueryRow;
          pageCount.value = res.paginationParams.maxPage;
        });
    };

    onMounted(() => {
      getHighwayStickerQuery(page.value).then(() => {
        hdCustomerVehicleListLoading.value = false;
      });
    });

    return {
      page,
      stickerCardItems,
      pageCount,
      totalVisible,
      hdCustomerVehicleList,
      hdCustomerVehicleListLoading,
      postCartHistoryQueryLoading,
      onChangePagination,
      onNavigationDashboardPage,
    };
  },
  components: {
    StickerCard,
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("DASHBOARDPAGE.TITLE")} | %s`,
    };
  },
});
